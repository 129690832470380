import React from "react";
import Cookies from 'universal-cookie'
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import { Link, BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Modal,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import ChatWAPageHeader from "components/Headers/ChatWAPageHeader.js";
import FooterWhite from "components/Footers/FooterWhite.js";
import axios from 'axios';

export class PresentationHeaderIdeation extends React.Component {
  constructor() {
		super();
		this.state = {
			userloggedin: {},
			color: [],
      city: null,
      city_lengkap: null,
      postal_code: null,
      wa_api: null,
      dc_name: null,
      get_number: false,
      openDialogLocation: false,
    };
	}

  setCloseModalLocation(){
    const cookies = new Cookies();
    console.log(cookies.get('city_lengkap'))
    console.log(cookies.get('city'))
    console.log(cookies.get('postal_code'))

    if (cookies.get('city') == null || cookies.get('city') == undefined){
      this.setState({
        city : null,
        city_lengkap : null,
        postal_code : null,
        openDialogLocation : false,
      })
    } else {
      axios.get('https://training-api.pti-cosmetics.com/area?kodepos=eq.'+cookies.get('postal_code')+'&limit=1')
      .then(result => {
        // console.log(result.data)
        // console.log(result.data[0].dc)
        var dc_get = result.data[0].dc
        axios.get('https://training-api.pti-cosmetics.com/area_wadc?dc=eq.'+dc_get)
        .then(response => {
          console.log(response.data)
          // console.log(response.data[0].number)
          this.setState({
            wa_api : response.data[0].number,
            dc_name : response.data[0].dc_name,
            get_number : true,
          })
        })
        .catch(error => {
          console.log(error)
        })
      })
      .catch(error => {
        console.log(error)
      })
      this.setState({
        city : cookies.get('city'),
        city_lengkap : cookies.get('city_lengkap'),
        postal_code : cookies.get('postal_code'),
        openDialogLocation : false,
      })
    }
  }

  componentWillMount(){
    this.setState({
      openDialogLocation : true,
    })
  }

  render(){
    let pageHeader = React.createRef();
    // console.log(this.state.city)
    // console.log(this.state.city_lengkap)
    // console.log(this.state.postal_code)
    // console.log(this.state.openDialogLocation)

    const openDialogLocation = this.state.openDialogLocation
    var href_wa_api
    var nama_dc
    if (this.state.get_number){
      href_wa_api = "https://api.whatsapp.com/send?phone=+62"+this.state.wa_api+"&text=Saya%0Atertarik%0Auntuk%0Aberkonsultasi%0Atentang%0Awardah%0Akosmetik"
      nama_dc = "DC "+this.state.dc_name
    } else {
      href_wa_api = "https://api.whatsapp.com/send?phone=+6281388894580&text=Saya%0Atertarik%0Auntuk%0Aberkonsultasi%0Atentang%0Awardah%0Akosmetik"
      nama_dc = "Redirect to DC JAKARTA"
    }
    // console.log(href_wa_api)
    if(this.state.city == null || this.state.city == undefined || this.state.address == null || this.state.address == undefined){
      return (
        <>
          {(function(){
            if(openDialogLocation == false){
              return (
                <head>
                  <meta http-equiv='refresh' content='0; URL=https://linktr.ee/homedeliverywardah'/>
                </head>
              )
            }
          })()}

          <div>
            <Modal
              isOpen={this.state.openDialogLocation}
              className="modal-sm"
              modalClassName="bd-example-modal-sm"
            >
              <div className="modal-header" style={{margin:'10px', paddingTop:'0px'}}>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick ={this.setCloseModalLocation.bind(this)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
                <h4 className="centerobject" style={{
                  padding:'5px',
                  textAlign: 'center',
                  margin: '5px',
                  fontFamily: 'Montserrat',
                  fontWeight:'600',
                  fontSize: '20px',
                }}>
                  Cek Lokasi
                </h4>
                <h4 className="centerobject" style={{
                  padding:'5px',
                  textAlign: 'center',
                  margin: '5px',
                  fontFamily: 'Montserrat',
                  fontWeight:'400',
                  fontSize: '15px',
                }}>
                  Cek Lokasi sebelum Konsultasi
                </h4>
              </div>

              <Grid container style={{marginBottom: '15px'}}>
                <Grid item xs={3} className='centerobject' style={{textAlign:'center'}}>
                </Grid>
                <Grid item xs={6} className='centerobject' style={{textAlign:'center'}}>
                  <Fab
                    variant="contained"
                    className="buttonSignin"
                    style={{background:'#12C069', height:'35px'}}
                  >
                    <h1 onClick ={this.setCloseModalLocation.bind(this)} style={{
                      color: '#ffffff',
                      marginBlockStart: '0px',
                      marginBlockEnd: '0px',
                      textAlign: 'center',
                      margin: '5px',
                      fontFamily: 'Montserrat',
                      fontWeight:'600',
                      fontSize: '10px',
                    }}>
                      Cek Lokasi
                    </h1>
                  </Fab>
                </Grid>
                <Grid item xs={3} className='centerobject' style={{textAlign:'center'}}>
                </Grid>
              </Grid>
            </Modal>
          </div>

        </>
      );
    } else {
      return (
        <>
          <div
            className="page-header"
            ref={pageHeader}
            style={{
              backgroundImage:
                "url(" + require("assets/img/sections/david-marcu.jpg") + ")"
            }}
          >
            <div className="filter" />
            <div className="content-center">
              <Container>
                <div className="motto">
                  <h1 className="title">Beauty Advisor Consultation</h1>
                  <h3 className="description">
                    Ask everything about Wardah Cosmetics on Whatsapp
                  </h3>
                  <br />
                  <Button
                    className="btn-round mr-1"
                    color="neutral"
                    href={href_wa_api}
                    target="_blank"
                    style={{backgroundColor:'green', color:'white'}}
                  >
                    <i className="fa fa-whatsapp" ></i>
                    {nama_dc}
                  </Button>

                  <h3 className="description">
                    {this.state.city}
                  </h3>
                  <h3 className="description">
                    {this.state.postal_code}
                  </h3>
                  <h3 className="description">
                    {this.state.city_lengkap}
                  </h3>
                </div>
              </Container>
            </div>
          </div>

          <div>
            <Modal
              isOpen={this.state.openDialogLocation}
              className="modal-sm"
              modalClassName="bd-example-modal-sm"
            >
              <div className="modal-header" style={{margin:'10px', paddingTop:'0px'}}>
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick ={this.setCloseModalLocation.bind(this)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
                <h4 className="centerobject" style={{
                  padding:'5px',
                  textAlign: 'center',
                  margin: '5px',
                  fontFamily: 'Montserrat',
                  fontWeight:'600',
                  fontSize: '20px',
                }}>
                  Cek Lokasi
                </h4>
                <h4 className="centerobject" style={{
                  padding:'5px',
                  textAlign: 'center',
                  margin: '5px',
                  fontFamily: 'Montserrat',
                  fontWeight:'400',
                  fontSize: '15px',
                }}>
                  Cek Lokasi sebelum Konsultasi
                </h4>
              </div>

              <Grid container style={{marginBottom: '15px'}}>
                <Grid item xs={3} className='centerobject' style={{textAlign:'center'}}>
                </Grid>
                <Grid item xs={6} className='centerobject' style={{textAlign:'center'}}>
                  <Fab
                    variant="contained"
                    className="buttonSignin"
                    style={{background:'#12C069', height:'35px'}}
                  >
                    <h1 onClick ={this.setCloseModalLocation.bind(this)} style={{
                      color: '#ffffff',
                      marginBlockStart: '0px',
                      marginBlockEnd: '0px',
                      textAlign: 'center',
                      margin: '5px',
                      fontFamily: 'Montserrat',
                      fontWeight:'600',
                      fontSize: '10px',
                    }}>
                      Cek Lokasi
                    </h1>
                  </Fab>
                </Grid>
                <Grid item xs={3} className='centerobject' style={{textAlign:'center'}}>
                </Grid>
              </Grid>
            </Modal>
          </div>

        </>
      );
    }

  }
}

export default PresentationHeaderIdeation;
