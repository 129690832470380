import React from "react";
import Cookies from 'universal-cookie'
import axios from 'axios'

// reactstrap components

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ColorNavbarIdeation from "components/Navbars/ColorNavbarIdeation.js";
import PresentationHeader from "components/Headers/PresentationHeader.js";
import FooterBlack from "components/Footers/FooterBlack.js";
import FooterBlackIdeation from "components/Footers/FooterBlackIdeation.js";
// sections for this page
import SectionInputIdeation from "./presentation-sections/SectionInputIdeation.js";
import SectionSummary from "./presentation-sections/SectionSummary.js";
import SectionComponents from "./presentation-sections/SectionComponents.js";
import SectionCards from "./presentation-sections/SectionCards.js";
import SectionContent from "./presentation-sections/SectionContent.js";
import SectionSections from "./presentation-sections/SectionSections.js";
import SectionExamples from "./presentation-sections/SectionExamples.js";
import SectionIcons from "./presentation-sections/SectionIcons.js";
import SectionFreeDemo from "./presentation-sections/SectionFreeDemo.js";
import SectionResponsive from "./presentation-sections/SectionResponsive.js";
import SectionOverview from "./presentation-sections/SectionOverview.js";
import SectionTestimonials from "./presentation-sections/SectionTestimonials.js";
import SectionSharing from "./presentation-sections/SectionSharing.js";

import SectionHeaderCards from "./wardahone/SectionHeaderCards.js";
import SectionConsultation from "./wardahone/SectionConsultation.js";
import SectionBelanjaDisini from "./wardahone/SectionBelanjaDisini.js";
import SectionFiturKecantikanDigital from "./wardahone/SectionFiturKecantikanDigital.js";
import SectionActivities from "./wardahone/SectionActivities.js";
import SectionGiveAwayTime from "./wardahone/SectionGiveAwayTime.js";
import SectionTopArticles from "./wardahone/SectionTopArticles.js";
import SectionWardahBeautyApp from "./wardahone/SectionWardahBeautyApp.js";

export class Presentation extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin: {},
			color: [],
			backgroundImage: [],
    };
	}

  componentDidMount() {
    const cookies = new Cookies();
    cookies.remove('city', {path: '/'});
    cookies.remove('city_lengkap', {path: '/'});
    cookies.remove('postal_code', {path: '/'});
    console.log('REMOVE COOKIES')

    var backgroundImage
    axios.get('https://training-api.pti-cosmetics.com/one_content_submission?order=order.desc&section=eq.9&title=not.is.null&select=image&active=eq.true&limit=1')
    .then(response => {
      console.log(response.data)
      this.setState({
        backgroundImage : response.data[0].image
      })
    })
    .catch(error => {
      console.log(error)
    })
  }

  render(){
    //<ColorNavbarIdeation />
    //<FooterBlackIdeation />
    // console.log(this.state.backgroundImage)
    const backgroundImage = this.state.backgroundImage
    console.log('Test')
    return (
      <>
        <div>
          {(function(){
            if(backgroundImage === null || backgroundImage === undefined){
              return (
                <img style={{width:'100%', position:'fixed', top:'0px', zIndex:'0'}}
                  alt="..."
                  src={require("assets/img/wardahone/BG-mobile.jpg")}
                />
              )
            } else {
              return (
                <img style={{width:'100%', position:'fixed', top:'0px', zIndex:'0'}}
                  alt="..."
                  src={backgroundImage}
                />
              )
            }
          })()}

          <SectionHeaderCards />
          <SectionConsultation />
          <SectionBelanjaDisini />
          <SectionFiturKecantikanDigital />
          <SectionActivities />
          <SectionGiveAwayTime />
          <SectionTopArticles />
          <SectionWardahBeautyApp />
        </div>
      </>
    );
  }
}

export default Presentation;
