import React from "react";

import ImageUploader from 'react-images-upload';
// reactstrap components

import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import Cookies from 'universal-cookie'
import {
  Container,
  Button,
  Label,
  Form,
  Modal,
  Card,
  CardBody,
  FormGroup,
  FormText,
  Input,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  UncontrolledPopover,
  UncontrolledTooltip,
  UncontrolledCollapse,
  PopoverHeader,
  PopoverBody,
  Row,
  Col,
} from "reactstrap";
// core components

const listdirectorate =
[
  'COMMERCIAL',
  'INTERNAL AUDIT',
  'CORPORATE SECRETARY',
  'FINANCE',
  'MARKETING',
  'PARAMA',
  'CREM',
  'PID',
  'RND',
  'SCM',
  'IT',
  'HR',
];

const listideatype =
[
	{
	    code: 'BM',
	    type: 'Business Model'
	},
	{
	    code: 'BP',
	    type: 'Business Process'
	},
	{
	    code: 'BPM',
	    type: 'Brand and Product Management'
	},
	{
	    code: 'CEW',
	    type: 'Customer Engagement Way'
	},
	{
	    code: 'SPD',
	    type: 'Sales and Product Delivery'
	},
	{
	    code: 'WOW',
	    type: 'Ways of Working'
	},
	{
	    code: 'OTHER',
	    type: 'Others'
	},
];

export class SectionInputIdeation extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin: {},
			color: [],
			openDialogMessage: false,
      openDialogProfilePicture: false,
      openDialogSignin: false,
      listdirectorate:[],
      listideatype:[],
      name: null,
      directorate: null,
      idea_title: null,
      idea_type: null,
      idea_typecode: null,
      idea_input: null,
      idea_background_input: null,
      noticeModal: false,
      uncompleteform: false,
      idea_submitted_id: null,
			isNewUpload: false,
			pictureDataURLs: null,
      idea_image: null,
      isSelectPhotoValid: true,
      isIdeaImageUpload: false,

      user_email: null,
      user_pass: null,
      encrypt_email: null,
      encrypt_pass: null,
      login_failed: null,
		};

		this.onDrop = this.onDrop.bind(this)
	}

  componentDidMount() {
    const cookies = new Cookies()
    this.setState({
			userloggedin: cookies.get('userloggedin'),
			login_status: cookies.get('login_status'),
			user_email: cookies.get('user_email'),
      listdirectorate: listdirectorate,
      listideatype: listideatype,
		})
	}

  onDrop(pictureFiles, pictureDataURLs) {
		//var idx_picture = pictureFiles.length - 1
		this.setState({
			isNewUpload: false,
		});

    console.log('FILES')
    console.log(pictureFiles[0])

    const data = new FormData()
    data.append('file', pictureFiles[0])
    console.log(data)
    axios.post("http://localhost:8003/upload",data)
    .then(result => {
      console.log(result.statusText)
    })
    .catch(error => {
      console.log(error)
    })

		if(pictureDataURLs !== undefined){
			this.setState({
	      pictures: pictureFiles,
				pictureDataURLs: pictureDataURLs[pictureFiles.length - 1],
				isNewUpload: true,
	    });
		}
  }

  selectPhoto = event =>{
    console.log(this.state.pictureDataURLs)
    if(this.state.pictureDataURLs === null){
      this.setState({
        isSelectPhotoValid: false,
      });
    } else {
      this.setState({
        idea_image: this.state.pictureDataURLs,
        isIdeaImageUpload: true,
        openDialogProfilePicture: false,
      });
    }
	}

  dialogprofilpicture = (e) =>{
		this.setState({
			openDialogProfilePicture: true,
      pictureDataURLs: null,
		})
	}

  closeDialogUploadImage = (e) =>{
		this.setState({
			openDialogProfilePicture: false,
      pictureDataURLs: null,
		})
	}

  handleChangeInputNama = event =>{
    console.log(event.target.value)
    this.setState({
      name : event.target.value
    });
	}

  handleChangeInputIdeaTitle = event =>{
    console.log(event.target.value)
    this.setState({
      idea_title : event.target.value
    });
	}

  handleChangeSelectDirectorate = event =>{
    console.log(event.target.value)
    this.setState({
      directorate : event.target.value
    });
	}

  handleChangeSelectTypeIdea = event =>{
    console.log(event.target.value)
    this.state.listideatype.map((row) => {
			if (event.target.value === row.type){
				this.setState({
					idea_type: row.type,
					idea_typecode: row.code,
				})
			}
		})
  }

  handleChangeInputIdea = event =>{
    console.log(event.target.value)
    this.setState({
      idea_input : event.target.value
    });
	}

  handleChangeInputBackgrundIdea = event =>{
    console.log(event.target.value)
    this.setState({
      idea_background_input : event.target.value
    });
	}

  closeModal = () => {
		this.setState({
			message: null,
			openDialogMessage: false,
      noticeModal: false,
			pictureDataURLs: null,
      openDialogSignin: false,
		})
	}

  closeSubmitMessage = () => {
    this.setState({
			openDialogMessage: false,
    })
    window.location.reload();
  }

	closeSnack = () => {
		this.setState({
			opensnackbar: false
		})
	}

	handleClickSubmitIdea = () => {

  	var dateFormat = require('dateformat')
		var today = new Date()
		var formattedtoday = dateFormat(today, "yyyy-mm-dd")
		console.log(formattedtoday)

    if(this.state.directorate == null || this.state.idea_typecode === null || this.state.idea_title === null || this.state.idea_background_input === null || this.state.idea_input === null) {
      this.setState({
        uncompleteform: true,
      })
    } else {
      var idea_data = {
  			"name": this.state.name,
  			"email": this.state.user_email,
        "directorate": this.state.directorate,
        "idea_title": this.state.idea_title,
        "idea_type": this.state.idea_typecode,
        "idea_background": this.state.idea_background_input,
        "idea": this.state.idea_input,
        "status" : "On Progress"
  		}
      console.log(idea_data)
    	axios.post('https://training-api.pti-cosmetics.com/idea_submited', idea_data)
  		.then(response => {
        console.log('Idea berhasil di submit')

        axios.get('https://training-api.pti-cosmetics.com/idea_submited?order=id.desc&limit=1')
    		.then(response => {
          console.log(response.data)
          console.log(response.data[0].id)
          this.setState({
    				openDialogMessage: true,
            noticeModal: true,
            idea_submitted_id: response.data[0].id
    			})

          //AXIOS POST IMAGE KE TABLE
          var idea_image_data = {
      			"idea_id": response.data[0].id,
      			"image": this.state.idea_image,
      		}
          axios.post('https://training-api.pti-cosmetics.com/idea_image', idea_image_data)
      		.then(response => {
            console.log('Idea Image berhasil di submit')
          })
          .catch(error => {
            console.log(error)
          })
        })
        .catch(error => {
          console.log(error)
        })
  		})
  		.catch(error => {
  			console.log(error)
  		})
    }

	}

  handleClickCekLogin = event =>{
    console.log('CEK LOGIN DULU BOS')
    this.setState({
			openDialogSignin: true,
  	})
  }

  encrypt = (event,obj) => {
    var encrypted = ""
    var keyLength = 100

    for (var i = 0; i < event.length; i++) {
      var ch = event.charAt(i)
      var ascii = event.charCodeAt(i)
      if(ascii >= 65 && ascii <= 90){
        var ascii = ascii + (keyLength%26)
        if(ascii > 90) {ascii = ascii - 26}
      } else if(ascii >= 97 && ascii <= 122){
        var ascii = ascii + (keyLength%26)
        if(ascii > 122) {ascii = ascii -26}
      }
      var res = String.fromCharCode(ascii);
      encrypted = encrypted+res;
    }
    return encrypted
  }

  handleChangeInputEmail = event =>{
    console.log(event.target.value)
    this.setState({
			user_email: event.target.value,
      encrypt_email: this.encrypt(event.target.value),
		})
  }

  handleChangeInputPassword = event =>{
    // console.log(event.target.value)
    this.setState({
			user_pass: event.target.value,
      encrypt_pass: this.encrypt(event.target.value),
		})
  }

  handleClickLoginButton = event => {
    const qs = require('querystring')
    var credential = this.state.encrypt_email+""+this.state.encrypt_pass

    const requestBody = {
      authentication: credential
    }

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    axios.post('https://booking-room.pti-cosmetics.com:9666/login', qs.stringify(requestBody), config)
    .then(response => {
      console.log(response.data)
      console.log('DONE : GET RESPONSE FROM LDAP')
      const cookies = new Cookies();
      cookies.set('userloggedin', response.data, {path: '/'});
      cookies.set('login_status', response.data.status, {path: '/'});
      cookies.set('user_email', this.state.user_email, {path: '/'});
      this.setState({
        userloggedin: response.data,
        login_status: response.data.status
      })
      window.location.reload();
    })
    .catch(error => {
      console.log(error)
      console.log('error',error.response)
      console.log('ERROR : NOT GET RESPONSE')
      this.setState({
        login_failed: true,
      })
    });

    console.log('finish to auth')
  }

  render(){
    const uncompleteform = this.state.uncompleteform
    const directorate = this.state.directorate
    const listdirectorate = this.state.listdirectorate
    const listideatype = this.state.listideatype
    const idea_type = this.state.idea_type
    const handleChangeInputIdea = this.handleChangeInputIdea.bind(this)
    const handleChangeInputBackgrundIdea = this.handleChangeInputBackgrundIdea.bind(this)
    const handleChangeInputNama = this.handleChangeInputNama.bind(this)
    const handleChangeInputIdeaTitle = this.handleChangeInputIdeaTitle.bind(this)
    const handleChangeSelectTypeIdea = this.handleChangeSelectTypeIdea.bind(this)
    const handleChangeSelectDirectorate = this.handleChangeSelectDirectorate.bind(this)
    const handleClickSubmitIdea = this.handleClickSubmitIdea.bind(this)
		const isNewUpload = this.state.isNewUpload
		const pictureDataURLs = this.state.pictureDataURLs
    const isSelectPhotoValid = this.state.isSelectPhotoValid
    const isIdeaImageUpload = this.state.isIdeaImageUpload
    const idea_image = this.state.idea_image
    const login_failed = this.state.login_failed

    console.log(this.state.openDialogSignin)
    console.log(this.state.login_failed)
    // console.log(this.state.user_email)
    // #2B678C

    var status_login = this.state.login_status
    var status_login = true

    if (status_login === true || status_login === 'true') {
      return (
        <>
          <div className="section" style ={{backgroundColor:'rgba(0,0,0,0)', paddingTop:'20px', paddingBottom:'200px'}}>
            <div style={{alignItems:'center', justifyContent:'center', flexDirection: 'column', display: 'flex', textAlign:'center'}}>
              <Container>
                <div className="title-brand">
                  <Button
                    id="buttonToggler"
                    style={{backgroundColor:"rgba(0,0,0,0)", margin:'10px', borderRadius:'20px', borderColor: '#ffffff'}}
                  >
                    Submit Your Idea
                  </Button>
                  <UncontrolledCollapse
                    toggler="#linkToggler,#buttonToggler"
                    style={{paddingLeft:'10px', paddingRight:'10px'}}
                  >
                    <Card style={{padding:'10px'}}>
                      <div>
                        <TextField
                          style={{ margin: '5px', width: '95%'}}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Nama"
                          onChange={handleChangeInputNama}
                        >
                        </TextField>

                        <FormControl style={{ margin: '5px', marginTop: '10px', width: '95%'}}>
                          <InputLabel style={{ marginLeft: '10px'}}>
                            Direktorat *
                          </InputLabel>

                          <Select
                            value={directorate}
                            variant="outlined"
                            onChange={handleChangeSelectDirectorate}>
                            {listdirectorate.map(row => (
                              <MenuItem
                                value={row}
                              >
                                {row}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <FormControl style={{ margin: '5px', marginTop: '10px', width: '95%'}}>
                          <InputLabel style={{ marginLeft: '10px'}}>
                            Kategori ide *
                          </InputLabel>
                          <Select
                            value={idea_type}
                            variant="outlined"
                            onChange={handleChangeSelectTypeIdea}>
                            {listideatype.map(row => (
                              <MenuItem
                                value={row.type}
                              >
                                {row.type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>

                        <TextField
                          style={{ margin: '5px', width: '95%'}}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Apa judul idemu"
                          onChange={handleChangeInputIdeaTitle}
                        >
                        </TextField>

                        <TextField style={{
                          margin: '5px',
                          width: '95%',
                        }}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Apa latar belakang dari idemu "
                          id="standard-multiline-flexible"
                          multiline
                          rows="4"
                          rowsMax="7"
                          onChange={handleChangeInputBackgrundIdea}
                        >
                        </TextField>

                        <TextField style={{
                          margin: '5px',
                          width: '95%',
                        }}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Jelaskan idemu disini "
                          id="standard-multiline-flexible"
                          multiline
                          rows="4"
                          rowsMax="7"
                          onChange={handleChangeInputIdea}
                        >
                        </TextField>

                        <Button
                          onClick={this.dialogprofilpicture.bind(this)}
                          style={{
                            backgroundColor:"rgba(0,0,0,0)",
                            marginBottom:'10px',
                            borderRadius:'20px',
                            borderColor: 'rgba(0,0,0,0.2)',
                            color: 'rgba(0,0,0,0.5)'
                          }}
                        >
                          Unggah Gambar
                        </Button>

                        {(function(){
                          if(isIdeaImageUpload){
                            return(
                              <div className="centerobject" style={{widht:'100%', margin:'10px'}}>
                                <img style={{
                                  width: '50%',
                                  zIndex: '0',
                                }}
                                  src={idea_image}
                                />
                              </div>
                            )
                          }
                        })()}

                        {(function(){
                          if(uncompleteform){
                            return(
                              <h4 className="centerobject" style={{
                                padding:'5px',
                                textAlign: 'center',
                                margin: '5px',
                                fontFamily: 'Montserrat',
                                fontWeight:'600',
                                fontSize: '12px',
                                color: '#e50000'
                              }}>
                                Please complete your form !
                              </h4>
                            )
                          }
                        })()}

                        <div className="centerobject" style={{widht:'100%'}}>
                          <Button
                            className="btn-round"
                            color="default"
                            type="button"
                            style={{backgroundColor:'#F1A124', borderColor:'#F1A124', width:'50%'}}
                            onClick={handleClickSubmitIdea}
                          >
                            Submit
                          </Button>
                        </div>

                      </div>

                    </Card>
                  </UncontrolledCollapse>
                </div>
              </Container>
            </div>

            <div style={{alignItems:'center', justifyContent:'center', flexDirection: 'column', display: 'flex', textAlign:'center'}}>
              <Container>
                <div className="title-brand">
                  <Button id="buttonToggler2" style={{backgroundColor:"rgba(0,0,0,0)", margin:'10px', borderRadius:'20px', borderColor: '#ffffff'}}>
                    Track Your Idea
                  </Button>
                  <UncontrolledCollapse toggler="#linkToggler,#buttonToggler2">
                    <div style={{paddingLeft:'10px', paddingRight:'10px'}}>
                      <Card style={{backgroundColor:'rgba(255,255,255,1)', padding:'10px', width:'100%'}}>
                        <TextField style={{
                          margin: '5px',
                          width: '95%',
                        }}
                          type="text"
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          label="Idea ID"
                          id="standard-multiline-flexible"

                        >
                        </TextField>
                        <div className="centerobject" style={{widht:'100%'}}>
                          <Button
                            className="btn-round"
                            color="default"
                            type="button"
                            id="buttonToggler3"
                            style={{backgroundColor:'#F1A124', borderColor:'#F1A124', width:'50%'}}
                          >
                            Track
                          </Button>
                        </div>
                      </Card>
                      <UncontrolledCollapse toggler="#linkToggler,#buttonToggler3">
                        <h2 className="presentation-subtitle text-center" style={{margin:'5px'}}>
                          Your idea is being processed. Let's explore more ideas!'
                        </h2>
                      </UncontrolledCollapse>
                    </div>
                  </UncontrolledCollapse>
                </div>
              </Container>
            </div>

            <Dialog
          		open = {this.state.openDialogProfilePicture}
          		closeOnDocumentClick
          		onClose= {this.closeModal}
              className="dialogpopup"
          	>
              <div style={{padding:'10px'}}>
                <h4 className="centerobject" style={{
                  padding:'5px',
                  textAlign: 'center',
                  margin: '5px',
                  fontFamily: 'Montserrat',
                  fontWeight:'600',
                  fontSize: '20px',
                }}>
                  Upload Image Idea
                </h4>
                <ImageUploader
    							withIcon={true}
                  buttonText='Choose images'
    							label='Max image size: 2 Mb (jpg,png,gif,jpeg)'
    							withPreview={false}
                  onChange={this.onDrop}
    							singleImage={true}
    							sizeFactor = {0.3}
                  imgExtension={['.jpg', '.gif', '.png', '.jpeg']}
                  maxFileSize={2000000}
                />
    						<div className='centerobject'style={{marginBottom:'15px', textAlign:'center'}}>
    							{(function(){
    								if(isNewUpload){
    									return (
    										<img style={{
    											width: '50%',
    											zIndex: '0',
    										}}
    											src={pictureDataURLs}
    										/>
    									)
    								}
    							})()}
    						</div>

                <div className='centerobject'style={{marginBottom:'15px', textAlign:'center'}}>
                  {(function(){
                    if(!isSelectPhotoValid){
                      return (
                        <h4 className="centerobject" style={{
                          padding:'5px',
                          textAlign: 'center',
                          margin: '5px',
                          fontFamily: 'Montserrat',
                          fontWeight:'600',
                          fontSize: '12px',
                          color: '#e50000'
                        }}>
                          There is No Photo Selected !
                        </h4>
                      )
                    }
                  })()}
                </div>

    						<Grid container style={{marginBottom: '5px'}}>
    							<Grid item xs={6} className='centerobject' style={{textAlign:'center'}}>
    								<Fab
    									variant="contained"
    									className="buttonSignin"
    									onClick={this.closeDialogUploadImage.bind(this)}
    									style={{background:'#e50000', height:'35px'}}
    								>
    									<h1 style={{
                        color: '#ffffff',
    										marginBlockStart: '0px',
    										marginBlockEnd: '0px',
                        textAlign: 'center',
                        margin: '5px',
                        fontFamily: 'Montserrat',
                        fontWeight:'600',
                        fontSize: '10px',
    									}}>
    										Cancel
    										</h1>
    								</Fab>
    							</Grid>
    							<Grid item xs={6} className='centerobject' style={{textAlign:'center'}}>
    								<Fab
    									variant="contained"
    									className="buttonSignin"
    									onClick={this.selectPhoto.bind(this)}
    									style={{background: "#12C069", height:'35px'}}
    								>
    									<h1 style={{
    										color: '#ffffff',
    										marginBlockStart: '0px',
    										marginBlockEnd: '0px',
                        textAlign: 'center',
                        margin: '5px',
                        fontFamily: 'Montserrat',
                        fontWeight:'600',
                        fontSize: '10px',
    									}}>
    										Select
    									</h1>
    								</Fab>
    							</Grid>
    						</Grid>
              </div>
  					</Dialog>

            <Dialog
              open={this.state.openDialogMessage}
              closeOnDocumentClick
              onClose={this.closeModal}
            >
              <h5 className="centerobject" style={{
                paddingTop:'5px',
                paddingBottom:'0px',
                textAlign: 'center',
                margin: '5px',
                fontFamily: 'Montserrat',
                fontWeight:'300',
                fontSize: '15px',
              }}>
                Thank you for being part of Innovation in Paragon.
              </h5>

              <h5 className="centerobject" style={{
                paddingTop:'5px',
                paddingBottom:'0px',
                textAlign: 'center',
                margin: '5px',
                fontFamily: 'Montserrat',
                fontWeight:'600',
                fontSize: '15px',
              }}>
                Please take notes of your ID number below to track your idea progress
              </h5>

              <h4 className="centerobject" style={{
                paddingTop:'5px',
                paddingBottom:'0px',
                textAlign: 'center',
                margin: '5px',
                fontFamily: 'Montserrat',
                fontWeight:'600',
                fontSize: '10px',
              }}>
                Your Idea ID =
              </h4>
              <h4 className="centerobject" style={{
                padding:'0px',
                textAlign: 'center',
                margin: '0px',
                fontFamily: 'Montserrat',
                fontWeight:'600',
                fontSize: '30px',
              }}>
                {this.state.idea_submitted_id}
              </h4>

              <h4 className="centerobject" style={{
                padding:'5px',
                textAlign: 'center',
                margin: '5px',
                fontFamily: 'Montserrat',
                fontWeight:'600',
                fontSize: '20px',
              }}>
                Explore More Ideas !
              </h4>

              <div className="centerobject" style={{widht:'100%', textAlign: 'center'}}>
                <Button
                  className="centerobject btn-round"
                  color="default"
                  type="button"
                  style={{backgroundColor:'#F1A124', borderColor:'#F1A124', width:'50%', marginBottom:'10px'}}
                  onClick={this.closeSubmitMessage.bind(this)}
                >
                  OK
                </Button>
              </div>

            </Dialog>

            <div style={{marginBottom:'50px'}}>
            </div>

          </div>
        </>
      );
    } else {
      return(
        <>
          <div className="section" style ={{backgroundColor:'rgba(0,0,0,0)', paddingTop:'20px', paddingBottom:'200px'}}>
            <div style={{alignItems:'center', justifyContent:'center', flexDirection: 'column', display: 'flex', textAlign:'center'}}>
              <Container>
                <div className="title-brand">
                  <Button
                    onClick={this.handleClickCekLogin.bind(this)}
                    style={{backgroundColor:"rgba(0,0,0,0)", margin:'10px', borderRadius:'20px', borderColor: '#ffffff'}}
                  >
                    Sign In to Submit Idea
                  </Button>
                </div>
              </Container>
            </div>

            <Modal
              isOpen={this.state.openDialogSignin}
              closeOnDocumentClick
          		onClose= {this.closeModal.bind(this)}
              modalClassName="modal-register"
            >
              <div className="modal-header no-border-header text-center">
                <button
                  aria-label="Close"
                  className="close"
                  data-dismiss="modal"
                  type="button"
                  onClick = {this.closeModal.bind(this)}
                >
                  <span aria-hidden={true}>×</span>
                </button>
                <h3 className="modal-title text-center">IdeaBox</h3>
                <p>Sign in to your account</p>
              </div>
              <div className="modal-body">
                <FormGroup>
                  <label>Email</label>
                  <Input
                    defaultValue=""
                    placeholder="Email"
                    type="text"
                    onChange={this.handleChangeInputEmail.bind(this)}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Password</label>
                  <Input
                    defaultValue=""
                    placeholder="Password"
                    type="password"
                    onChange={this.handleChangeInputPassword.bind(this)}
                  />
                </FormGroup>
                <div>
                  {(function(){
                    if(login_failed == true || login_failed == 'true'){
                      return(
                        <p className="centerobject" style={{
                          padding:'0px',
                          textAlign: 'center',
                          marginBottom: '10px',
                          fontFamily: 'Montserrat',
                          fontWeight:'400',
                          fontSize: '12px',
                          color: '#e50000'
                        }}>
                          Incorrect email and password !
                        </p>
                      )
                    }
                  })()}
                </div>
                <Button
                  block
                  className="btn-round"
                  color="default"
                  onClick={this.handleClickLoginButton.bind(this)}
                >
                  Sign in
                </Button>
              </div>


            </Modal>

            <div style={{marginBottom:'50px'}}>
            </div>

          </div>
        </>
      )
    }

  }
}

export default SectionInputIdeation;
