import React from "react";
import { Link } from "react-router-dom";
import Cookies from 'universal-cookie'
// nodejs library that concatenates strings
import classnames from "classnames";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Modal,
  Container,
  UncontrolledTooltip
} from "reactstrap";
// core components

function ColorNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [openDialogLogout, setOpenDialogLogout] = React.useState(false);

  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 499 ||
        document.body.scrollTop > 499
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 500 ||
        document.body.scrollTop < 500
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };

  });

  function handleClickLogout(e) {
    // e.preventDefault();
    const cookies = new Cookies();
    cookies.remove('userloggedin', {path: '/'});
    cookies.remove('login_status', {path: '/'});
    cookies.remove('user_email', {path: '/'});
    console.log('The link was clicked.');
    console.log(e);
    setOpenDialogLogout(false);
    window.location.reload();
  }
  // #2B678C
  console.log('OPEN DIALOG LOGOUT')
  console.log(openDialogLogout)
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        className={classnames("fixed-top", navbarColor)}
        expand="lg"
        id="navbar-main"
        style={{paddingTop:'0px',backgroundColor:'#2B678C'}}
      >
        <Container style={{backgroundColor:'#2B678C'}}>
          <div className="navbar-translate">
            <NavbarBrand id="navbar-brand" to="/presentation" tag={Link} style={{paddingBottom:'0px'}}>
              <img style={{width:'20%', margin:'0px'}}
                alt="..."
                src={require("assets/img/wardahone/wardah-big.png")}
              />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
              Paper Kit PRO React
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(false);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>

          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle to="/presentation" tag={Link} className="mr-2" color="default" nav>
                  Home
                </DropdownToggle>
              </UncontrolledDropdown>
              {/*<UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Profile
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem
                    onClick={() => {
                      document.documentElement.classList.toggle("nav-open");
                      setOpenDialogLogout(true);
                    }}
                  >
                    <i className="nc-icon nc-single-02" />
                    LogOut
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>*/}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>

      <div>
        <Modal
          isOpen={openDialogLogout}
          className="modal-sm"
          modalClassName="bd-example-modal-sm"
          toggle={() => setOpenDialogLogout(false)}
        >
          <div className="modal-header" style={{margin:'10px', paddingTop:'0px'}}>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => setOpenDialogLogout(false)}
            >
              <span aria-hidden={true}>×</span>
            </button>
            <h4 className="centerobject" style={{
              padding:'5px',
              textAlign: 'center',
              margin: '5px',
              fontFamily: 'Montserrat',
              fontWeight:'600',
              fontSize: '20px',
            }}>
              Sign Out
            </h4>
            <h4 className="centerobject" style={{
              padding:'5px',
              textAlign: 'center',
              margin: '5px',
              fontFamily: 'Montserrat',
              fontWeight:'400',
              fontSize: '15px',
            }}>
              Are you sure you want to Sign Out ?
            </h4>
          </div>

          <Grid container style={{marginBottom: '15px'}}>
            <Grid item xs={6} className='centerobject' style={{textAlign:'center'}}>
              <Fab
                variant="contained"
                className="buttonSignin"
                style={{background:'#12C069', height:'35px'}}
              >
                <h1 onClick={() => setOpenDialogLogout(false)} style={{
                  color: '#ffffff',
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  textAlign: 'center',
                  margin: '5px',
                  fontFamily: 'Montserrat',
                  fontWeight:'600',
                  fontSize: '10px',
                }}>
                  Back
                </h1>
              </Fab>
            </Grid>
            <Grid item xs={6} className='centerobject' style={{textAlign:'center'}}>
              <Fab
                variant="contained"
                className="buttonSignin"
                style={{background: "#e50000", height:'35px'}}
              >
                <h1 onClick={() => handleClickLogout()} style={{
                  color: '#ffffff',
                  marginBlockStart: '0px',
                  marginBlockEnd: '0px',
                  textAlign: 'center',
                  margin: '5px',
                  fontFamily: 'Montserrat',
                  fontWeight:'600',
                  fontSize: '10px',
                }}>
                  Sign Out
                </h1>
              </Fab>
            </Grid>
          </Grid>
        </Modal>
      </div>
    </>
  );
}

export default ColorNavbar;
