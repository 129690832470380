import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Grid from '@material-ui/core/Grid';
import Geocode from "react-geocode";

export class SectionWardahBeautyApp extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin: {},
    };
	}

  render(){
		// #2B678C
    return (
      <>
        <div >
          <div
					style={{ backgroundColor: 'rgba(0,0,0,0)', padding:'5px'}}
            className="section"
          >
            <div style={{marginTop:'5px'}}>
              <Container>
								<div className="title-brand" style={{color: '#000000'}}>
									<div style={{alignItems:'center', justifyContent:'center', flexDirection: 'column', display: 'flex', textAlign:'center'}}>
									  <h1 style={{fontSize: '15px', fontFamily: 'Montserrat', fontWeight:'600', marginTop:'0px'}}>
	                    <b>Wardah Beauty Apps</b>
	                  </h1>
	                  <h1 style={{fontSize: '10px', fontFamily: 'Montserrat', marginTop:'0px'}}>
	                    <b>Unduh aplikasi Wardah Beauty App untuk perangkat mobile</b>
	                  </h1>
										<a
											href="https://play.google.com/store/apps/details?id=mobi.mobileforce.wardah&hl=en"
											className="text-center"
											style={{padding:'0px', margin:'10px', backgroundColor:'rgba(0,0,0,0)'}}
										>
											<img style={{width:'100px', marginBottom:'20px'}}
												alt="..."
												src={require("assets/img/wardahone/playstore.png")}
											/>
										</a>
	                </div>
                </div>
							</Container>

            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SectionWardahBeautyApp;
