import React from "react";
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import {  UncontrolledCollapse, Button, Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import axios from "axios";

export class SectionCardsIdeation extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin: {},
			color: [],
			dataContent : [],
		};
	}

  componentDidMount() {
		axios.get('https://training-api.pti-cosmetics.com/one_content_submission?order=order.asc&section=eq.2&title=not.is.null&select=id,title,section,link,image&active=eq.true')
    .then(response => {
      // console.log(response.data)
      this.setState({
        dataContent: response.data
      })
    })
    .catch(error => {
      console.log(error)
    })

	}

	handleClickCountSectionLink (row, obj) {
    // console.log(row)
    // console.log(obj)
		axios.get('https://training-api.pti-cosmetics.com/one_content_submission?id=eq.'+row.id)
    .then(response => {
      // console.log(response.data)
      // console.log(response.data[0].click)
			var items = {
				"click": response.data[0].click + 1,
			}
			axios.patch('https://training-api.pti-cosmetics.com/one_content_submission?id=eq.'+row.id, items)
	  	.then(response => {
	      console.log('Idea Status berhasil di update')
	    })
	    .catch(error => {
	      console.log(error)
	    })
    })
    .catch(error => {
      console.log(error)
    })

		var dateFormat = require('dateformat')
		var today = new Date();
    var todayDate = dateFormat(today, "yyyy-mm-dd")
		// console.log(todayDate)
		var materi = {
      "id_content" : row.id,
      "id_section" : row.section,
      "click" : 1,
			"date" : todayDate,
    }
		// console.log(materi)
    axios.post('https://training-api.pti-cosmetics.com/one_clickcount_content', materi)
    .then(response => {
      console.log("SUBMIT UPDATE BERHASIL")
    })
    .catch(error => {
      console.log(error)
    })

	}

	render(){
		// #2B678C
		// console.log(this.state.dataContent)
		// console.log(this.state.dataContent[0])
	  return (
	    <>
				<div style={{marginTop:'10px'}}>
				</div>

				<div className="section" style ={{backgroundColor:'rgba(0,0,0,0)', paddingTop:'0px', paddingBottom:'0px'}}>
					<div style={{alignItems:'center', justifyContent:'center', flexDirection: 'column', display: 'flex', textAlign:'center'}}>
						<Container>
							<div className="title-brand" style={{color: '#000000'}}>
								<h1 style={{fontSize: '15px', fontFamily: 'Montserrat', fontWeight:'600', marginTop:'0px', marginBottom:'5px'}}>
									<b>Belanja Disini</b>
								</h1>
							</div>

							<Grid container justify="center" style={{ padding:'0px', margin:'0px'}}>
								<Grid item xs={12}  style={{ padding:'0px', marginLeft:'0px', marginRight:'0px', display: 'flex',}}>
									<Grid container justify="center" className="homeGrid" style={{ padding:'0px', margin:'0px'}}>

										{this.state.dataContent.map((row)=> (
											<Grid item xs={4}  style={{ padding:'5px', marginLeft:'0px', marginRight:'0px'}}>
												<Card
													data-background="color"
													data-color="white"
													style={{margin:'0px'}}
												>
													<a
														href={row.link}
														className="text-center"
														target="_blank"
														style={{padding:'0px', margin:'10px', backgroundColor:'rgba(0,0,0,0)'}}
														onClick ={this.handleClickCountSectionLink.bind(this,row)}
													>
														<img style={{width:'100px'}}
															alt="..."
															src={row.image}

														/>
														<h4 className="centerobject" style={{
															padding:'0px',
															margin: '0px',
															textAlign: 'center',
															fontFamily: 'Montserrat',
															fontWeight:'600',
															fontSize: '10px',
															color: '#000000',
															height:'30px'
														}}>
															{row.title}
														</h4>
													</a>
												</Card>
											</Grid>
										))}

									</Grid>
								</Grid>
							</Grid>

						</Container>
					</div>
	      </div>

				<div style={{marginBottom:'15px'}}>
        </div>
	    </>
	  );
	}
}

export default SectionCardsIdeation;
