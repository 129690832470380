import React from "react";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export class PresentationHeaderIdeation extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin: {},
			color: [],
			dataContent: [],
    };
	}

  componentDidMount() {
		console.log('Ready')
		axios.get('https://training-api.pti-cosmetics.com/one_content_submission?order=order.asc&section=eq.8&title=not.is.null&select=id,title,section,link,image&active=eq.true')
    .then(response => {
      // console.log(response.data)
      this.setState({
        dataContent: response.data
      })
    })
    .catch(error => {
      console.log(error)
    })
	}

	handleClickCountSectionLink (row, obj) {
    // console.log(row)
    // console.log(obj)
		axios.get('https://training-api.pti-cosmetics.com/one_content_submission?id=eq.'+row.id)
    .then(response => {
      // console.log(response.data)
      // console.log(response.data[0].click)
			var items = {
				"click": response.data[0].click + 1,
			}
			axios.patch('https://training-api.pti-cosmetics.com/one_content_submission?id=eq.'+row.id, items)
	  	.then(response => {
	      console.log('Idea Status berhasil di update')
	    })
	    .catch(error => {
	      console.log(error)
	    })
    })
    .catch(error => {
      console.log(error)
    })

		var dateFormat = require('dateformat')
		var today = new Date();
    var todayDate = dateFormat(today, "yyyy-mm-dd")
		// console.log(todayDate)
		var materi = {
      "id_content" : row.id,
      "id_section" : row.section,
      "click" : 1,
			"date" : todayDate,
    }
		// console.log(materi)
    axios.post('https://training-api.pti-cosmetics.com/one_clickcount_content', materi)
    .then(response => {
      console.log("SUBMIT UPDATE BERHASIL")
    })
    .catch(error => {
      console.log(error)
    })

	}

  render(){
		// #2B678C
		const dataContent = this.state.dataContent
	  return (
      <>
        <div >
          <div
					style={{ backgroundColor: 'rgba(0,0,0,0)', padding:'10px'}}
            className="section"
          >
            <div style={{marginTop:'10px'}}>
              <Container>
								<div className="centerobject" style={{color: '#000000'}}>
									<div style={{alignItems:'center', justifyContent:'center', flexDirection: 'column', display: 'flex', textAlign:'center'}}>
										<img style={{width:'100px'}}
											src={require("assets/img/wardahone/wardah-big.png")}
										/>
									</div>

								  <Swiper
                  	spaceBetween={5}
                  	slidesPerView={1}
                  	pagination={{ clickable: true }}
                  	onSlideChange={() => console.log('slide change')}
                  	onSwiper={(swiper) => console.log(swiper)}
                  >
                  	{dataContent.map((row)=> (
                  		<SwiperSlide>
                  			<Card
                  				data-background="color"
                  				data-color="white"
                  				style={{marginTop:'10px', marginBottom:'0px'}}
                  			>
													<a
														href={row.link}
														className="text-center"
														target="_blank"
														style={{padding:'0px', margin:'10px', backgroundColor:'rgba(0,0,0,0)'}}
														onClick ={this.handleClickCountSectionLink.bind(this,row)}
													>
	                  				<img
	                  					src={row.image}
	                  					style={{width:'100%', padding:'0px', margin:'0px'}}
	                  				>
                  					</img>
													</a>
                  			</Card>
                  		</SwiperSlide>
                  	))}
                  	...
                  </Swiper>

                </div>
              </Container>

            </div>
          </div>
        </div>
      </>
    );
  }

}

export default PresentationHeaderIdeation;
