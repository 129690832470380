import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  

  return (
    <>
      <div
        className="page-header"
        ref={pageHeader}
        style={{
          backgroundImage:
            "url(" + require("assets/img/sections/david-marcu.jpg") + ")"
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="motto">
              <h1 className="title">Beauty Advisor Consultation</h1>
              <h3 className="description">
                Ask everything about Wardah Cosmetics on Whatsapp
              </h3>
              <br />
              <Button
                className="btn-round mr-1"
                color="neutral"
                href="https://api.whatsapp.com/send?phone=+6285950060155&text=Saya%0Atertarik%0Auntuk%0Aberkonsultasi%0Atentang%0Awardah%0Akosmetik"
                target="_blank"
                style={{backgroundColor:'green', color:'white'}}
              >
                Chat with us
              </Button>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
