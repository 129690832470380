import React from "react";
import { Link } from "react-router-dom";
import axios from 'axios'
import Cookies from 'universal-cookie'
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import Grid from '@material-ui/core/Grid';
import Geocode from "react-geocode";

export class PresentationHeaderIdeation extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin: {},
			color: [],
			location: null,
			islocationon: false,
			address: null,
			city: null,
    };
	}

  onClickWhatsApp = () => {
	  const location = window.navigator && window.navigator.geolocation
		var loc = null
		// console.log('location')
		// console.log(location)
		if(location) {
			location.getCurrentPosition((position) => {
				this.setState({
					islocationon: true
				})

				setTimeout(()=>{ loc = 'https://google.com/maps/search/'+position.coords.latitude+'++'+position.coords.longitude }, 5000);
				this.setState({
					location: 'https://google.com/maps/search/'+position.coords.latitude+'++'+position.coords.longitude
				})
				//console.log('https://google.com/maps/search/'+position.coords.latitude+'++'+position.coords.longitude)

				Geocode.setApiKey("AIzaSyBgJCMSTgGnd-6szqLhwc5byVRTteri9gg");					// set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
				Geocode.setLanguage("id");														// set response language. Defaults to english.
				// set response region. Its optional.
				Geocode.setRegion("es");														// A Geocoding request with region=es (Spain) will return the Spanish city.
				Geocode.enableDebug();															// Enable or disable logs. Its optional.
				Geocode.fromLatLng(position.coords.latitude, position.coords.longitude).then(	// Get address from latidude & longitude.
					response => {
						console.log(response)
						console.log(response.results[8].address_components[0].long_name)
						console.log(response.results[6].address_components[0].long_name)
						console.log(response.results[8].formatted_address)
						var kota = response.results[8].address_components[0].long_name
						var postal_code = response.results[6].address_components[0].long_name
						var kota_lengkap = response.results[8].formatted_address
				    var address = response.results[8].formatted_address;
				    console.log(address);
						const cookies = new Cookies();
			      cookies.set('city', kota, {path: '/'});
			      cookies.set('city_lengkap', kota_lengkap, {path: '/'});
			      cookies.set('postal_code', postal_code, {path: '/'});
				    this.setState({
							address: response.results[8].formatted_address,
							city: response.results[8].address_components[0].long_name,
						})
					},
					error => {
					    console.error(error);
					}
				);
			}, (error) => {
				console.log(error)
				alert('Lokasi tidak bisa didapatkan')
				this.setState({
					islocationon: false
				})
			})
		}
	}

  render(){
		// #2B678C
		// console.log(this.state.islocationon)
		// console.log(this.state.location)
		// console.log(this.state.address)
		// console.log(this.state.city)
    return (
      <>
        <div >
          <div
					style={{ backgroundColor: 'rgba(0,0,0,0)', padding:'5px'}}
            className="section"
          >
            <div style={{marginTop:'5px'}}>
              <Container>
								<div className="title-brand" style={{color: '#000000'}}>
									<div style={{alignItems:'center', justifyContent:'center', flexDirection: 'column', display: 'flex', textAlign:'center'}}>
									  <h1 style={{fontSize: '15px', fontFamily: 'Montserrat', fontWeight:'600', marginTop:'0px'}}>
	                    <b>Kami Siap Membantu</b>
	                  </h1>
	                  <h1 style={{fontSize: '10px', fontFamily: 'Montserrat', marginTop:'10px'}}>
	                    <b>Hubungi Beauty Advisor terdekat di kotamu melalui menu Beauty Advisor untuk memesan produk dan konsultasi gratis</b>
	                  </h1>
	                </div>
                </div>
								<Grid container justify="center" style={{ padding:'0px', margin:'0px'}}>
				          <Grid item xs={12}  style={{ padding:'0px', marginLeft:'0px', marginRight:'0px', display: 'flex',}}>
				            <Grid container justify="center" className="homeGrid" style={{ padding:'0px', margin:'0px'}}>
				              <Grid item xs={4}  style={{ padding:'5px', marginLeft:'0px', marginRight:'0px'}}>
												<Card
													data-background="color"
													data-color="white"
													style={{margin:'0px'}}
													to="/chatwithus" tag={Link}
													onClick={this.onClickWhatsApp.bind(this)}
												>
												  <CardBody className="text-center" style={{padding:'0px', margin:'10px'}}>
				                    <img style={{width:'100px'}}
				                      alt="..."
				                      src={require("assets/img/wardahone/whatsapp-logo.png")}
				                    />
				                    <h4 className="centerobject" style={{
				                      padding:'0px',
				                      margin: '0px',
				                      textAlign: 'center',
				                      fontFamily: 'Montserrat',
				                      fontWeight:'600',
				                      fontSize: '10px',
				                      color: '#000000',
				                    }}>
				                      Beauty Advisor
				                    </h4>
				                  </CardBody>
				                </Card>
				              </Grid>
				            </Grid>
				          </Grid>
			          </Grid>
              </Container>

            </div>
          </div>
        </div>

      </>
    );
  }

}

export default PresentationHeaderIdeation;
