import React from "react";

import axios from 'axios'
import Cookies from 'universal-cookie'
// reactstrap components
import {
  Button,
  Card,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";
import { Link, BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import ColorNavbarIdeation from "components/Navbars/ColorNavbarIdeation.js";

import readXlsxFile from 'read-excel-file'

export class LoginPage extends React.Component {
	constructor() {
		super();
		this.state = {
			userloggedin: {},
      login_status : null,
			color: [],
      user_email: null,
      user_pass: null,
      encrypt_email: null,
      encrypt_pass: null,
		};
	}

  componentDidMount() {

    var input = document.getElementById('input')
    console.log(input)
    input.addEventListener('change', () => {
      readXlsxFile(input.files[0]).then((rows) => {
        console.log(rows)
        console.log(rows[0])
        console.log(rows[0][0])
        // `rows` is an array of rows
        // each row being an array of cells.
      })
    })

    const cookies = new Cookies()
    this.setState({
			userloggedin: cookies.get('userloggedin'),
			login_status: cookies.get('login_status'),
    })
	}

  encrypt = (event,obj) => {
    var encrypted = ""
    var keyLength = 100

    for (var i = 0; i < event.length; i++) {
      var ch = event.charAt(i)
      var ascii = event.charCodeAt(i)
      if(ascii >= 65 && ascii <= 90){
        var ascii = ascii + (keyLength%26)
        if(ascii > 90) {ascii = ascii - 26}
      } else if(ascii >= 97 && ascii <= 122){
        var ascii = ascii + (keyLength%26)
        if(ascii > 122) {ascii = ascii -26}
      }
      var res = String.fromCharCode(ascii);
      encrypted = encrypted+res;
    }
    return encrypted
  }

  handleChangeInputEmail = event =>{
    console.log(event.target.value)
    this.setState({
			user_email: event.target.value,
      encrypt_email: this.encrypt(event.target.value),
		})
  }

  handleChangeInputPassword = event =>{
    // console.log(event.target.value)
    this.setState({
			user_pass: event.target.value,
      encrypt_pass: this.encrypt(event.target.value),
		})
  }

  handleClickLoginButton = event => {
    const qs = require('querystring')
    var credential = this.state.encrypt_email+""+this.state.encrypt_pass

    const requestBody = {
      authentication: credential
    }

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    axios.post('https://booking-room.pti-cosmetics.com:9666/login', qs.stringify(requestBody), config)
    .then(response => {
      console.log(response.data)
      console.log('DONE : GET RESPONSE FROM LDAP')
      const cookies = new Cookies();
      cookies.set('userloggedin', response.data, {path: '/'});
      cookies.set('login_status', response.data.status, {path: '/'});
      this.setState({
        userloggedin: response.data,
        login_status: response.data.status
      })
    })
    .catch(error => {
        console.log(error)
        console.log('error',error.response)
        console.log('ERROR : NOT GET RESPONSE')
    });

    console.log('finish to auth')
  }

  render(){
    console.log('LOGIN HERE')
    console.log(this.state.userloggedin)
    console.log(this.state.login_status)
    // console.log(this.state.userloggedin.status)
    if (this.state.login_status === 'true' || this.state.login_status === true) {
      return(
        <Redirect from="*" to="/presentation" />
      )
    } else {
      return (
        <>
          <ColorNavbar />
          <div className="wrapper">
            <div
              className="page-header"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/sections/bruno-abatti.jpg") + ")"
              }}
            >
              <div className="filter" style={{marginTop:'200px'}}/>


              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="4" md="6" sm="6">
                    <Card className="card-register">
                      <CardTitle tag="h3">Welcome</CardTitle>
                      <input type="file" id="input" />

                      <Form className="register-form">
                        <label>Email</label>
                        <Input
                          className="no-border"
                          placeholder="Email"
                          type="email"
                          onChange={this.handleChangeInputEmail.bind(this)}
                        />
                        <label>Password</label>
                        <Input
                          className="no-border"
                          placeholder="Password"
                          type="password"
                          onChange={this.handleChangeInputPassword.bind(this)}
                        />
                        <Button
                          block
                          className="btn-round"
                          color="danger"
                          onClick={this.handleClickLoginButton.bind(this)}
                        >
                          Login
                        </Button>
                      </Form>
                      <div className="forgot">
                        <Button
                          className="btn-link"
                          color="danger"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Forgot password?
                        </Button>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <div className="demo-footer text-center">
                  <h6>
                    © {new Date().getFullYear()}, made with{" "}
                    <i className="fa fa-heart heart" /> by Creative Tim
                  </h6>
                </div>
              </Container>
            </div>
          </div>
        </>
      );
    }
  }
}

export default LoginPage;
